<template>
  <NuxtLayout :name="layout">
    <NuxtPage />
  </NuxtLayout>

  <UNotifications />
</template>

<script setup lang="ts">
const { $sentrySetUser } = useNuxtApp();
const user = useSupabaseUser();
const layout = ref("default");
const router = useRouter();

watchEffect(async () => {
  if (user.value) {
    if (process.client) {
      $sentrySetUser({ id: user.value?.id, email: user.value?.email });
    }
  }

  // Redirection selon le rôle
  if (user.value?.user_metadata.role === "company") {
    if (router.currentRoute.value.path === "/change-password") {
      layout.value = "default";
    } else {
      layout.value = "company";
    }
  } else {
    layout.value = "worker";
  }
});
</script>

<style lang="scss" scoped></style>
